@config '../tailwind.config.js';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './assets/colors';
@import './assets/fonts';
@import './assets/custom';

body,
html {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  background-color: $base-gray;
}

.body {
  position: relative;
  min-height: 100%;
  padding: 0;
  margin: 0;
}

.main-container {
  background-color: $base-gray;
  height: 100%;
  min-height: 100vh;
  max-width: 600px;
  margin: 0 auto;
}

.main-loader {
  width: 100%;
  height: 100%;
  text-align: center;

  $loader_bar_1_position: 0;
  $loader_bar_2_position: 20px;
  $loader_bar_3_position: 40px;
  $loader_bar_4_position: 60px;
  $loader_border_size: 6px;

  .preloader {
    position: fixed;
    top: calc(50% - 350px);
    left: calc(50% - 350px);
    width: 700px;
    height: 700px;
    border-radius: 50%;
    background-color: $preloader_bg;
    z-index: 9;

    .loader-text {
      position: absolute;
      font-size: 32px;
      color: $loader_text;
      font-weight: 500;
      bottom: 202px;
      left: calc(50% - 57px);
    }
  }

  .loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 206px;
    height: 206px;
    margin: -150px 0 0 -103px;

    .loader-bar-1 {
      position: absolute;
      top: $loader_bar_1_position;
      left: $loader_bar_1_position;
      right: $loader_bar_1_position;
      bottom: $loader_bar_1_position;
      border-radius: 50%;
      border: $loader_border_size solid $loader_border;
      border-top-color: $loader_bar_1;
      animation: spin 3s linear infinite;
    }

    .loader-bar-2 {
      position: absolute;
      top: $loader_bar_2_position;
      left: $loader_bar_2_position;
      right: $loader_bar_2_position;
      bottom: $loader_bar_2_position;
      border-radius: 50%;
      border: $loader_border_size solid $loader_border;
      border-top-color: $loader_bar_2;
      animation: spin 2s linear infinite;
    }

    .loader-bar-3 {
      position: absolute;
      top: $loader_bar_3_position;
      left: $loader_bar_3_position;
      right: $loader_bar_3_position;
      bottom: $loader_bar_3_position;
      border-radius: 50%;
      border: $loader_border_size solid $loader_border;
      border-top-color: $loader_bar_3;
      animation: spin 1.5s linear infinite;
    }

    .loader-bar-4 {
      position: absolute;
      top: $loader_bar_4_position;
      left: $loader_bar_4_position;
      right: $loader_bar_4_position;
      bottom: $loader_bar_4_position;
      border-radius: 50%;
      border: $loader_border_size solid $loader_border;
      border-top-color: $loader_bar_4;
      animation: spin 1s linear infinite;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
