@import './colors';

// styles for notifications
gan-notification {
  position: fixed;
  top: 3px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1001;
  display: flex;
  flex-direction: column;

  > *:not(gan-snackbar),
  gan-snackbar > .snackbar-container {
    margin: 4px 0;
    padding: 15px 36px 15px 20px;
    font-size: 14px;
    font-weight: 200;
    color: $base_white1;
    min-height: 48px;
    box-sizing: border-box;
    min-width: 300px;
    box-shadow: 0 0 5px 0 $base-gray-shadow;
    opacity: 0.9;
    position: relative;

    &.success {
      background-color: $base-success;
    }

    &.error {
      background-color: $base-error;
    }

    button {
      display: flex !important;
      top: 0;
      right: 0;
      align-items: center;
      justify-content: center;
      width: 36px !important;
      height: 36px !important;
      position: absolute !important;

      .mat-button-wrapper {
        line-height: 20px;

        .mat-icon {
          font-size: 20px;
          line-height: 20px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
