@use 'sass:map';

@import 'light.theme';
@import 'dark.theme';

@mixin spread-map($map: ()) {
  @each $key, $value in $map {
    #{$key}: $value;
  }
}

@function theme-var($key, $fallback: null, $map: $theme-map-light) {
  @if not map-has-key($map, $key) {
    @error "key: '#{$key}', is not a key in map: #{$map}";
  }
  @if ($fallback) {
    @return var($key, $fallback);
  } @else {
    @return var($key);
  }
}
