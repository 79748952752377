@use 'sass:map';
@use '@angular/material' as mat;

@import './custom.palettes';

$primaryTheme: mat.define-palette($palette-n, 900);
$accentTheme: mat.define-palette($palette-o, 500);
$warnTheme: mat.define-palette(mat.$red-palette);
$default-theme: mat.define-light-theme($primaryTheme, $accentTheme, $warnTheme);

$light-theme-color-config: mat.get-color-config($default-theme);
$light-theme-primary-palette: map.get($light-theme-color-config, 'primary');

$theme-map-light: (
  $--theme-base-black: mat.get-color-from-palette($light-theme-primary-palette, '50-contrast'),
  $--theme-base-white: mat.get-color-from-palette($light-theme-primary-palette, '300-contrast'),
);
