@import 'themes/themes.mixin';

// basic colors for Project
$base-black: theme-var($--theme-base-black);
$base-white: theme-var($--theme-base-white);
$base-primary: #353649;
$base-gray: #eeeeee;
$base-white1: #efefef;
$base-gray-shadow: rgba(0, 0, 0, 0.2);
$base-success: #43a047;
$base-error: #f44336;
$base-warning: #ffc107;
$base-info: #2f96b4;
$dark-gray: rgba(0, 0, 0, 0.54);
$pale-grey: #eceff1;

// alerts
$alert-danger-color: #fcedf1;
$alert-warning-color: #fff8d1;
$alert-success-color: #e8f2e4;

// warning colors
$critical-color: #d43f51;
$warning-color: #ffd431;
$normal-color: #2fa81c;
$critical-color-50: #fcedf1;
$warning-color-50: #fff8d1;
$normal-color-50: #adf694;

// loader
$preloader-bg: rgba(197, 208, 222, 0.07);
$loader-text: #8c9fb6;
$loader-border: #f4f7fa;
$loader-bar-1: #ff7043;
$loader-bar-2: #7c4dff;
$loader-bar-3: #ec407a;
$loader-bar-4: #26c6da;

// How To
$how-to-text: $base-black;
