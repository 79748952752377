@use 'sass:map';
@use '@angular/material' as mat;

@import './custom.palettes';

$dark-primary: mat.define-palette($palette-n-dark, 500);
$dark-accent: mat.define-palette($palette-o, 500);
$dark-warn: mat.define-palette(mat.$red-palette);
$dark-theme: mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);

$dark-theme-color-config: mat.get-color-config($dark-theme);
$dark-theme-primary-palette: map.get($dark-theme-color-config, 'primary');

$theme-map-dark: (
  $--theme-base-white: mat.get-color-from-palette($dark-theme-primary-palette, '300-contrast'),
  $--theme-base-black: mat.get-color-from-palette($dark-theme-primary-palette, '50-contrast'),
);
