@use '@angular/material' as mat;

@import './custom.palettes';
@import './themes.mixin';

.school-light {
  @include mat.all-component-themes($default-theme);

  @include spread-map($theme-map-light);
}

.school-dark {
  @include mat.all-component-themes($dark-theme);

  @include spread-map($theme-map-dark);
}
